import {useFormContext} from '@rvf/remix'
import type {ButtonProps} from './Button'
import {Button} from './Button'

export interface SubmitButtonProps extends Omit<ButtonProps, 'variant' | 'key'> {
  label: string
  submittingLabel: string
  variant?: ButtonProps['variant']
}

export const SubmitButton: React.FC<SubmitButtonProps> = ({label, submittingLabel, variant = 'purple', ...rest}) => {
  const {formState} = useFormContext()
  const disabled = formState.isSubmitting || !formState.isValid

  return (
    <Button {...rest} variant={variant} type="submit" disabled={disabled}>
      {formState.isSubmitting ? submittingLabel : label}
    </Button>
  )
}
